export const locationsMapped: { [key: string]: string } = {
  'Bologna, Metropolitan City of Bologna, Italy': 'Bologna, Italy',
  'Rome, Metropolitan City of Rome Capital, Italy': 'Rome, Italy',
  'Rome, Metropolitan City of Rome, Italy': 'Rome, Italy',
  'Milano, Metropolitan City of Milan, Italy': 'Milan, Italy',
  'Kraków, Poland': 'Krakow, Poland',
  'Turin, Metropolitan City of Turin, Italy': 'Turin, Turin, Italy',
  'Napoli, Metropolitan City of Naples, Italy': 'Napoli, Italy',
  'Naples, Metropolitan City of Naples, Italy': 'Naples, Italy',
  'Venice, Metropolitan City of Venice, Italy': 'Venice, Italy',
  Luxembourg: 'Luxembourg Grand Duchy of Luxembourg',
  'Luxembourg City, Luxembourg': 'Luxembourg Grand Duchy of Luxembourg',
  'Brooklyn, MI, USA': 'Brooklyn, NY, USA',
  'Trento, Autonomous Province of Trento, Italy': 'Trento, Italy',
};
